<template>
  <div id="organizationManagement" class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">鉴定机构管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">鉴定机构列表</a>
        </span>
      </div>

      <div class="framePage-body">
        <!-- 查询 -->
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 10px">
            <div title="机构名称" class="searchboxItem ci-full">
              <span class="itemLabel">机构名称:</span>
              <el-select
                size="small"
                v-model="compId"
                remote
                :remote-method="getCompanyList"
                filterable
                clearable
                placeholder="请至少输入两个字搜索"
                style="width: 100%"
              >
                <el-option
                  v-for="item in CompanyList"
                  :key="item.compId"
                  :label="item.compName"
                  :value="item.compId"
                ></el-option>
              </el-select>
            </div>
            <div title="负责人" class="searchboxItem ci-full">
              <span class="itemLabel">负责人:</span>
              <el-input
                v-model="compUser"
                type="text"
                size="small"
                placeholder="请输入负责人"
                clearable
              />
            </div>
            <div title="联系方式" class="searchboxItem ci-full">
              <span class="itemLabel">联系方式:</span>
              <el-input
                v-model="compPhone"
                type="text"
                size="small"
                placeholder="请输入联系方式"
                clearable
              />
            </div>
          </div>
          <div class="searchbox">
            <div title="到期时间" class="searchboxItem ci-full">
              <span class="itemLabel">到期时间:</span>
              <el-date-picker
                v-model="exportDate"
                size="small"
                type="daterange"
                style="width: 5rem"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              />
            </div>
            <div title="所在区域" class="searchboxItem ci-full">
              <span class="itemLabel">所在区域:</span>
              <el-cascader
                v-model="areaId"
                :options="areatreeList"
                :props="propsarea"
                clearable
                filterable
                size="small"
              ></el-cascader>
            </div>
            <el-button
              class="bgc-bv"
              style="margin: 0 10px"
              round
              @click="getData()"
              >搜索</el-button
            >
            <el-button
            class="bgc-bv"
            style="margin: 0 10px"
            round
            @click="add()"
            >新增鉴定机构</el-button
          >
          </div>
        </div>
        <!-- 列表 -->
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                fixed
              />
              <el-table-column
                label="机构名称"
                align="left"
                prop="compName"
                show-overflow-tooltip
                fixed
              />
              <el-table-column
                label="所在区域"
                align="left"
                prop="areaNamePath"
                show-overflow-tooltip
              />
              <el-table-column
                label="负责人"
                align="left"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{
                  scope.row.compUser || "--"
                }}</template>
              </el-table-column>
              <el-table-column
                label="联系方式"
                align="left"
                prop="compPhone"
                show-overflow-tooltip
              />
              <el-table-column
                label="开通时间"
                align="right"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{
                  scope.row.createTime | momentDate
                }}</template>
              </el-table-column>
              <el-table-column
                label="到期时间"
                align="right"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{
                  scope.row.maturityDate | momentDate
                }}</template>
              </el-table-column>

              <el-table-column label="操作" align="center" fixed="right">
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="onEdit(scope.row)"
                    >编辑</el-button
                  >
                </div>
              </el-table-column>
              <!-- <Empty slot="empty" /> -->
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>

    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogShow"
      width="700px"
      top="2%"
      :before-close="handleClose"
    >
    <div class="ovy-a" style="height:600px">
      <component
        v-if="dialogShow"
        :is="componentName"
        :stu="stu"
        :id="id"
        @close="close"
      ></component>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import List from "@/mixins/List";
import PageNum from "@/components/PageNum.vue";

export default {
  components: {
    Operation: (resolve) => {
      require(["./operation.vue"], resolve);
    },
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      CompanyList: [],
      areaId: "", // 地区
      compId: "", // 机构id
      compUser: "", // 负责人
      compPhone: "", // 联系方式
      exportDate: "", // 到期时间
      areatreeList: [], // 行政区划
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      dialogShow: false, // 弹窗显示状态
      componentName: "", // 弹窗要显示的组件
      dialogTitle: "", //
      stu: "", // 操作状态 新增 add / 修改 edit
    };
  },
  watch: {
    compId: function (val) {
      if (val == "") {
        this.CompanyList = [];
      }
    },
  },
  created() {
    this.getData();
    this.getareatree();
  },
  methods: {
    // 获取机构
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    // 关闭弹窗
    close() {
      this.dialogShow = false;
      this.getData();
    },
    // 获取数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };

      if (this.compUser) {
        params.compUser = this.compUser;
      }
      if (this.compPhone) {
        params.compPhone = this.compPhone;
      }

      if (this.exportDate) {
        params.maturityDateBegin = this.exportDate[0];
        params.maturityDateEnd = this.exportDate[1];
      }
      if (this.compId) {
        params.compId = this.compId;
      }

      if (this.areaId) {
        params.areaId = this.areaId;
      }

      this.doFetch({
        url: "/run/evaluate/company/pageList",
        params,
        pageNum,
      });
      
      this.tableData.forEach((element) => {
        console.log(element);
      });
    },
    // 编辑
    onEdit(row) {
      this.id = row.compId;
      this.dialogTitle = "编辑鉴定机构";
      this.componentName = "Operation";
      this.dialogShow = true;
      this.stu = "edit";
    },
    // 新增机构
    add() {
      this.dialogShow = true;
      this.componentName = "Operation";
      this.dialogTitle = "新增鉴定机构";
      this.stu = "add";
    },
    /**
     *  行政区划
     */
    getareatree() {
      this.$post("/sys/area/tree")
        .then((res) => {
          this.areatreeList = res.data || [];
        })
        .catch(() => {
          return;
        });
    },
       getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 3 ) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>

<style lang="less" scoped>
#organizationManagement {
}
</style>